import * as React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../components/default-layout'
import { SEO, buildCatalogLDSJSON } from '../components/seo'
import { MainImage } from '../components/image'
import { useElectricianImages } from '../components/electrician-image'
import { SectionOne, SectionTwo, Contact } from '../components/page'
import { ProductsSection } from '../components/product-section'
import { CatalogComponent } from '../components/catalog'
import { LocationSplitList } from '../components/locations'
import { getCatalog } from '../data/electrician-catalog'
import { SpecificLogo } from '../components/logo'
import { Slider } from '../components/slider'

export const Head = () => {
  const images = useElectricianImages()
  const catalog = React.useMemo(() => getCatalog(images), [images])

  return (
    <SEO
      article={false}
      title={'Electricistas a domicilio'}
      description={
        'Electricistas a domicilios las 24 horas del día en Bogotá. Electricistas industriales, comerciales, residenciales, reparaciones de electrodomésticos.'
      }
    >
      <script
        type="application/ld+json"
        children={buildCatalogLDSJSON('Electrician', {
          name: 'Domicilios 24/7',
          categories: catalog.categories,
        })}
      />
    </SEO>
  )
}

const Electricians: React.FC<PageProps> = () => {
  const images = useElectricianImages()
  const catalog = React.useMemo(() => getCatalog(images), [images])

  return (
    <Layout
      theme={'blue'}
      visible={{ electrician: true }}
      logo={<SpecificLogo children={'Electricistas'} />}
    >
      <Slider
        id={'inicio'}
        business={'Electricistas profesionales'}
        wallpaper={'electricianSlider'}
        overlay={0.8}
        pageTitle={'Servicio de Electricistas 24/7'}
        subTitle={'Electricistas las 24 horas del día'}
        pageDescription={
          <>
            Servicio de{' '}
            <strong>electricistas las 24 horas del día en Bogotá</strong>,{' '}
            electricistas industriales, comerciales y residenciales, diseño de
            instalaciones eléctricas, montoje de subestaciones eléctricas,
            paneles solares, cámaras de seguridad, sisteas de aire
            acondicionado, etc. Cumplimos con el{' '}
            <b>reglamento técnico RETIE y la norma NTC 2050</b>.
          </>
        }
        goTo={'#servicios'}
      />

      <ProductsSection
        id={'servicios'}
        pageTitle={'Nuestros servicios'}
        business={'Electricistas Domicilios 24/7'}
        businessType={'Electrician'}
        pageSubTitle={
          'Estos son los servicios más populares con los cuales contamos.'
        }
        products={<CatalogComponent light catalog={catalog} />}
      />

      <SectionOne
        id={'nosotros'}
        pageTitle={
          'Somos profesionales certificados que trabajan con altos estandares de calidad.'
        }
        description={
          <>
            <p>
              Contamos con una trayectoria de más de 5 años donde nuestros
              clientes siempre se han mostrado satisfechos con nuestros altos
              estándares de calidad.
            </p>
            <p>
              Las 24 del día, 7 días a la semana. En caso de que tengas un
              problema no te preocupes nos movilizamos hasta el lugar que te
              encuentres. Ofrecemos distintos servicio al mejor precio y con
              exelente calidad en el sector industrial, comercial y residencial,
              además reparamos duchas eléctricas, cortos eléctricos, estufas
              eléctricas, neveras, lavadoras, hornos eléctricos, etc.
            </p>
          </>
        }
        image={<MainImage alt={'Electricista'} reference={'woman'} />}
      />

      <SectionTwo
        id={'cobertura'}
        image={
          <MainImage
            alt={'Desplazamiento en vehículo'}
            reference={'fastDelivery'}
            className={'rounded'}
          />
        }
        pageTitle={'No importa donde te encuentres, allí estaremos.'}
        description={
          <>
            <p>
              Disponemos de varias sedes distribuidas en toda{' '}
              <strong>Bogotá</strong>, nuestros profesionales cuentan con
              transporte optimo para desplazarce al lugar que desees con las
              herramientas necesarias. Estos son algunos de los lugares donde
              contamos con más solicitudes de servicios:
            </p>
            <LocationSplitList columns={2} />
          </>
        }
      />

      <Contact
        id={'contacto'}
        business={'Electrician'}
        businessName={'Electricistas'}
        priceRange={"$15.000 - $12'000.000"}
      />
    </Layout>
  )
}

export default Electricians
